<template>
  <div class="content" :class="{ separator: index > 0, reverse: !isEven }">
    <div class="feature-container">
      <div class="feature-name">
        {{ service.name }}
      </div>
      <div class="feature-list">
        <div
          v-for="feature in service.features"
          :key="feature.title"
          class="feature"
        >
          <p class="feature-title">{{ feature.title }}</p>
          <div class="feature-content">
            <p>{{ feature.content }}</p>
          </div>
        </div>
      </div>

      <div class="more">
        <router-link :to="service.more"
          >Learn More <i class="arrow"></i
        ></router-link>
      </div>
    </div>

    <div class="image-container">
      <img :src="service.image" alt="service" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceItem",
  data() {
    return {
      currentImage: "",
    };
  },
  computed: {
    isEven() {
      return this.index % 2 === 0;
    },
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
}

.feature-container {
  width: 40%;
}

.image-container {
  width: 60%;
}

.separator {
  margin-top: 10px;
}

.content.reverse {
  flex-direction: row-reverse;
}

.feature {
  width: calc(50% - 10px);
  text-align: center;
}

.feature-name {
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 15px;
}

.feature-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.feature-title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 8px;
}

.feature-content {
  color: #666;
}

.feature-content p {
  font-size: 16px;
  line-height: 1.5;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.more {
  margin-top: 15px;
  text-align: center;
  margin-bottom: 15px;
}

.more a {
  color: var(--theme-color);
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
}

.arrow::after {
  content: ">>";
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 4px;
  display: inline-block;
}
</style>
