<template>
  <div class="section">
    <h1>CustomerIndustry</h1>
  </div>
</template>

<script>
export default {
  name: "CustomerIndustry",
};
</script>
