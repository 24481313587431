<template>
  <div>
    <div>
      <SlogonShow />
      <ProductShow />
      <ServiceShow />
      <WhyChooseUs />
      <CustomerIndustry class="hide" />
      <BlogNews class="hide" />
      <ContactUs />
    </div>
  </div>
</template>

<script>
import SlogonShow from "@/section/SlogonShow.vue";
import ProductShow from "@/section/ProductShow.vue";
import ServiceShow from "@/section/ServiceShow.vue";
import CustomerIndustry from "@/section/CustomerIndustry.vue";
import BlogNews from "@/section/BlogNews.vue";
import ContactUs from "@/section/ContactUs.vue";
import WhyChooseUs from "@/section/WhyChooseUs.vue";

export default {
  name: "HomePage",
  components: {
    SlogonShow,
    ProductShow,
    ServiceShow,
    CustomerIndustry,
    BlogNews,
    ContactUs,
    WhyChooseUs,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.section {
  padding: 1rem 2rem;
}

.title {
  font-size: 23px;
  font-weight: 600;
}
</style>
