<template>
  <div class="section">
    <div class="title">
      <h1>Products We Have Made</h1>
    </div>
    <div v-for="product in products" :key="product.id" class="item">
      <ProductItem :product="product" />
    </div>
  </div>
</template>

<script>
import ProductItem from "@/components/ProductItem.vue";
import { mapGetters } from "vuex";

export default {
  name: "ProductShow",
  components: {
    ProductItem,
  },
  computed: {
    ...mapGetters(["products"]),
  },
};
</script>

<style scoped>
.item-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.item {
  display: inline-block;
  max-width: 400px;
  margin: 2rem 2rem;
}
</style>
