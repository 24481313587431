<template>
  <div class="section">
    <div class="hero">
      <img
        src="@/assets/cnc-bg.jpg"
        alt="CNC Machining Background"
        class="hero-bg"
      />
      <div class="content">
        <h1 class="slogan">
          Precision Beyond Measure, Excellence Beyond Compare
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SlogonShow",
};
</script>

<style scoped>
.section {
  padding: 0;
}

.hero {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
}

.hero-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.slogan {
  color: black;
  font-size: 3rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
</style>
