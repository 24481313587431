<template>
  <div class="section">
    <div class="title">
      <h1>Our Services</h1>
    </div>
    <div v-for="(service, index) in services" :key="service.id" class="item">
      <ServiceItem :service="service" :index="index" />
    </div>
  </div>
</template>

<script>
import ServiceItem from "@/components/ServiceItem.vue";
import { mapGetters } from "vuex";

export default {
  name: "ServiceShow",
  components: {
    ServiceItem,
  },
  computed: {
    ...mapGetters(["services"]),
  },
};
</script>

<style scoped>
.section {
  background-color: var(--section-bg-reverse-color);
}

.content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.feature-container {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.feature {
  width: calc(50% - 10px);
  text-align: center;
}

.feature-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 8px;
}

.feature-content {
  color: #666;
}

.feature-content p {
  font-size: 16px;
  line-height: 1.5;
}

.image-container {
  width: 60%;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
</style>
