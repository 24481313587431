import { createStore } from "vuex";

import product from "./modules/product";
import service from "./modules/service";

const store = createStore({
  modules: {
    product,
    service,
  },
});

export default store;
