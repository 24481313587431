<template>
  <div class="product">
    <router-link :to="`/product/${product.id}`">
      <img class="big-img" :src="product.cover" alt="empty" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  data() {
    return {};
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.big-img {
  max-width: 300px;
  max-height: 300px;
  object-fit: contain;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.product {
  display: flex;
  flex-direction: column;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}

.name {
  font-weight: bold;
  flex-grow: 1;
  text-align: left;
}
</style>
