const state = {
  products: [
    {
      id: 1,
      cover:
        "https://www.custommechparts.com/Data/custommechparts/template/en/sample/3/07.jpg",
    },
    {
      id: 2,
      cover:
        "https://www.custommechparts.com/Data/custommechparts/template/en/sample/3/08.jpg",
    },
    {
      id: 2,
      cover:
        "https://www.custommechparts.com/Data/custommechparts/template/en/sample/3/01.jpg",
    },
    {
      id: 2,
      cover:
        "https://www.custommechparts.com/Data/custommechparts/template/en/sample/3/02.jpg",
    },
    {
      id: 2,
      cover:
        "https://www.custommechparts.com/Data/custommechparts/template/en/sample/3/03.jpg",
    },
    {
      id: 2,
      cover:
        "https://www.custommechparts.com/Data/custommechparts/template/en/sample/3/04.jpg",
    },
    {
      id: 2,
      cover:
        "https://www.custommechparts.com/Data/custommechparts/template/en/sample/3/05.jpg",
    },
    {
      id: 2,
      cover:
        "https://www.custommechparts.com/Data/custommechparts/template/en/sample/3/06.jpg",
    },
  ],
};

const mutations = {
  UPDATE_PRODUCT_ITEMS(state, payload) {
    state.products = payload;
  },
};

const actions = {};

const getters = {
  products: (state) => state.products,
};

const productModule = {
  state,
  mutations,
  actions,
  getters,
};

export default productModule;
